import EventBus from 'event-bus'
import { getFeatureEnabled } from 'feature-flag'
import { has_scope, is_nxt } from 'phoenix-session-helpers'
/**
 *
 * @param {string, number} value
 * @returns
 */
const isNumeric = (value) => !isNaN(parseFloat(value)) && isFinite(value)

/**
 *
 * @param {blob} file
 * @returns
 */
const formFileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result.split(',').slice(-1).join(','))
    reader.onerror = (error) => reject(error)
})
/**
 *
 * @param {object} obj vue component
 * @returns
 */
const vueComponentEmitter = function (obj) {
    return (...args) => {
        obj.$emit(...args)
    }
}

/**
 *
 * @param {object} obj vue component
 * @returns
 */
const vueComponentChangeRoute = function (obj) {
    return (name, params) => {
        if (!params && obj.$route && obj.$route.name && name === obj.$route.name) return null
        if (obj.$router) {
            obj.$router.push({ name, params })
        } else {
            EventBus.$emit('click:link', { name, params })
            console.log('router not available')
        }
    }
}

/**
 *
 * @param {object} obj vue component
 * @returns
 */
const vueComponent = (obj) => {
    return {
        emit: vueComponentEmitter(obj),
        routerPush: vueComponentChangeRoute(obj),
        forceUpdate: () => obj.$forceUpdate(),
        has_router: !!obj.$router
    }
}

/**
 *
 * @param {object} e
 * @returns
 */
const preventDoubleSpace = (e) => {
    if (e.keyCode === 32 && e.target && e.target.value) {
        const position = e.target.selectionStart
        if (position > -1) {
            const prev_char = e.target.value[position - 1]
            if (prev_char === ' ') {
                e.preventDefault()
                return true
            }
            const next_char = e.target.value[position]
            if (next_char === ' ') {
                e.preventDefault()
                return true
            }
        }
    }

    return null
}

/**
 *
 * @param {object} e
 * @returns
 */
const preventNumber = (e) => {
    const numeric_codes = [
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
        96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 109, 110, 111 // numberic pad
    ]
    const key_code = e.keyCode || e.which
    if (numeric_codes.includes(key_code)) {
        e.preventDefault()
        return true
    }

    return null
}

const specialKeys = [
    'Backspace',
    'Tab',
    'Enter',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'Home',
    'End',
    'PageUp',
    'PageDown',
    'Delete',
    'NumLock',
    'CapsLock'
]

/**
 *
 */
const emptyRouteRule = () => ({
    actions: [{
        action: 'disconnect'
    }]
})

/**
 *
 * @param {string} name
 * @returns
 */
const getFirstAndLastName = (name) => {
    name = name.trim()
    const white_space_index = name.indexOf(' ')
    let first_name = ''
    let last_name = ''
    if (white_space_index > -1) {
        const regex = /[^a-zA-Z0-9_ '-]/g
        first_name = name.substring(0, white_space_index).replace(regex, '')
        last_name = name.substring(white_space_index).replace(regex, '')
    } else {
        first_name = name
        last_name = name
    }

    return { first_name: first_name.trim(), last_name: last_name.trim() }
}

/**
 *
 * @param {object} account
 * @param {string} form_id
 * @param {object} form_config
 * @returns
 */
const submitHubspotForm = async (account, form_id, form_config = {}) => {
    if (!account) throw new Error('Error submitting hubspot form. Account not available.')

    const default_config = {
        portal_id: '6860053',
        sfdc_campaign_id: '7011K0000014F5mQAE',
        page_uri: `${document.location.protocol}//${document.location.hostname}`,
        page_name: 'Phone.com > Console'
    }
    const config = {
        ...default_config,
        ...form_config
    }

    const { primary_email, company, phone } = account.contact
    const { first_name, last_name } = getFirstAndLastName(account.name)

    const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${config.portal_id}/${form_id}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fields: [
                    {
                        objectTypeId: '0-1',
                        name: 'email',
                        value: primary_email
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'firstname',
                        value: first_name
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'lastname',
                        value: last_name
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'company',
                        value: company
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'phone',
                        value: phone
                    }
                ],
                context: {
                    pageUri: config.page_uri,
                    pageName: config.page_name,
                    sfdcCampaignId: config.sfdc_campaign_id
                }
            })
        }
    )

    if (!response.ok) {
        throw new Error('Error submitting hubspot form')
    }
    return response.json()
}

/**
 *
 */
const emptyAddress = () => JSON.parse(JSON.stringify({
    line_1: '',
    line_2: '',
    city: '',
    province: '',
    postal_code: '',
    country: 'US'
}))

/**
 *
 * @param {object} branding
 * @returns
 */
const getHelpLink = async (branding) => {
    const is_preprod = process.env.VUE_APP_STAGE === 'preprod'
    if (branding.data.code === 'idseal') {
        return 'https://www.idseal.com/support'
    }
    if (branding.data.code === 'tailor') {
        return 'https://support.tailorbrands.com/hc/en-us'
    }
    const has_lepro_help_link = await getFeatureEnabled('console.show_lapro_help_link')
    const has_avo_help_link = await getFeatureEnabled('console.show_avo_help_link')
    if (!['avo', 'avo_master'].includes(branding.data.code) && !has_avo_help_link && !has_lepro_help_link) {
        return is_preprod ? 'https://dev.www.phone.com/support/' : 'https://www.phone.com/support/'
    }
    if (has_avo_help_link) {
        return is_preprod ? 'https://dev.www.phone.com/splash-pages/reccp-faq/' : 'https://www.phone.com/splash-pages/reccp-faq/'
    }
    if (['avo', 'avo_master'].includes(branding.data.code)) {
        return 'https://www.alliancevirtualoffices.com/faq'
    }

    return null
}

/**
 *
 * @param {object} route
 * @param {object} branding
 * @param {object} session
 * @returns
 */
const consoleUserCanAccessPageMiddleware = async (route, branding, session) => {
    if (route && route.meta) {
        if (route.meta.allowed_for_scopes && route.meta.allowed_for_scopes.length) {
            for (const scope of route.meta.allowed_for_scopes) {
                const user_has_scope = await has_scope(session, scope)
                if (user_has_scope) {
                    return true // csr check most likely, if they are csr, we dont check the rest
                }
            }
        }
        if (route.meta.allowed_for_user_type && ['nxt', 'classic'].includes(route.meta.allowed_for_user_type)) {
            const user_is_nxt = await is_nxt(session)
            if (route.meta.allowed_for_user_type === 'nxt' && user_is_nxt) {
                return true
            }
            if (route.meta.allowed_for_user_type === 'classic' && !user_is_nxt) {
                return true
            }
        }
        if (route.meta.features && route.meta.features.length) {
            const are_enabled = []
            for (const feature of route.meta.features) {
                const feature_is_enabled = await getFeatureEnabled(feature)
                are_enabled.push(feature_is_enabled)
            }
            return !!are_enabled.find((x) => x)
        }
    }

    if (
        branding &&
        branding.data &&
        branding.data.routes &&
        branding.data.routes.length &&
        !branding.data.routes.includes(route.name)
    ) {
        return false
    }

    return true
}

/**
 * @param {object} data object containing basic information about user. It should have at least 'id' prop.
 * Under account prop should have info related to user's account that is returned from: .../v4/accounts/voip_id
 * We wont always have account prop, depending on API scope (ex. subaccounts-owner)
 * example: {
    "id": 1888562,
    "token": "Bearer xEjpx2N2Mhz9...",
    "expiration": 1703853078000,
    "account": {
        "id": 1888562,
        "name": "Boris Cuzovic Junior",
        "username": "boris.cuzovic@sentice.com",
        "default_voip_phone_id": 2643452,
        "contact": {
            "id": 7311286,
            ...
        },
        "billing_account_id": 2273097,
        "billing_contact": {
            "id": 7311286,
            ...
        },
        "is_restricted": false,
        "record_all_calls": "none",
        "branding_id": 586,
    }
}
 */
const FSIdentifyUser = (data) => {
    if (
        typeof FS !== 'undefined' &&
        // eslint-disable-next-line no-undef
        FS &&
        // eslint-disable-next-line no-undef
        typeof FS.identity === 'function' &&
        data &&
        typeof data === 'object' &&
        data.id
    ) {
        const account = data.account && typeof data.account === 'object' ? data.account : {}
        // eslint-disable-next-line no-undef
        FS.identify(data.id, {
            voip_id: data.id,
            name: account.name,
            username: account.username
        })
    } else {
        console.warn('FS not available, or data object not configured properly')
    }

    return true
}

/**
 *
 * @param {object} vuetify_obj this.$vuetify
 * @param {object} new_theme {base, light1...}
 * @param {string} theme light, dark
 */
const applyVuetifyTheme = (vuetify_obj, new_theme, theme = 'light') => {
    Object.keys(new_theme).forEach((i) => {
        vuetify_obj.theme.themes[theme][i] = new_theme[i]
    })
}

/**
 *
 */
export {
    isNumeric,
    formFileToBase64,
    vueComponent,
    vueComponentEmitter,
    vueComponentChangeRoute,
    preventDoubleSpace,
    preventNumber,
    specialKeys,
    emptyRouteRule,
    getFirstAndLastName,
    submitHubspotForm,
    emptyAddress,
    getHelpLink,
    consoleUserCanAccessPageMiddleware,
    FSIdentifyUser,
    applyVuetifyTheme
}
