import Vue from 'vue'
import Vuetify from 'vuetify'
import Branding from 'branding'
import { mainTheme } from 'themes'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import './assets/font-change.sass'
import CustomIcons from './vuetify-setup/custom-icons'

const branding = new Branding()

export default generateVuetifyTheme()
Vue.use(Vuetify)

/**
 * @param {object} themes
 */
function generateVuetifyTheme (themes = (mainTheme.vuetify[branding.branding] || mainTheme.vuetify.default)) {
    if (themes) {
        const opts = {
            icons: {
                iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
                values: CustomIcons
            },
            theme: { themes, options: { customProperties: true } }
        }

        return new Vuetify(opts)
    }
}
/**
 *
 */
export { generateVuetifyTheme }
